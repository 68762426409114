const Flickity = require('flickity');

const utils = require('fizzy-ui-utils');


document.addEventListener('DOMContentLoaded',function() {
    
    const disablePointerEvents = function(container, flkty) {
        
        let pause; 
        let cell = container.getElementsByClassName('carousel-cell');
        
        flkty.on('dragStart', function() {            
            
            for (let i = 0; i < cell.length; i++) {
                cell[i].getElementsByTagName('a')[0].style.pointerEvents = 'none';
            }
        });
        
        flkty.on('dragEnd', function() {

            setTimeout(function() {
                
                for (let i = 0; i < cell.length; i++) {
                    cell[i].getElementsByTagName('a')[0].style.pointerEvents = 'all';
                } 

            }, 100);

        });
    };


    if (typeof Flickity === 'function') {

      var proto = Flickity.prototype;
    
      proto.watchCSS = function () {
        var watchOption = this.options.watchCSS;
        if (!watchOption) {
          return;
        }
    
        var afterContent = getComputedStyle(this.element, ':after').content;
        // activate if :after { content: 'flickity' }
        if (afterContent.indexOf('flickity') != -1) {
          // ---modification start---
          // check for watchCSS options changes
          if (typeof watchOption === 'object') {
            this.watchCSSOptionsChange(afterContent);
          }
          // ---modification end---
          this.activate();
        } else {
          this.deactivate();
        }
      };
    
      proto.watchCSSOptionsChange = function (afterContent) {
        // store the current breakpoint identifier
        if (!this._currentOptionsIdentifier) {
          this._currentOptionsIdentifier = '';
        }
        // trim flickity. and surrounding quotes from the new breakpoint identifier
        var identifier = afterContent.substring(0, afterContent.length - 1).substring(10);
    
        // check for breakpoint change
        if (this._currentOptionsIdentifier !== identifier) {
    
          // if the original options have been cloned apply them to reset
          if (typeof this.options._flickityInitialOptions === 'object') {
    
            this.options = this.options._flickityInitialOptions;
            this._currentOptionsIdentifier = '';
          }
    
          // check if the new breakpoint options exist
          if (typeof this.options.watchCSS[identifier] === 'object') {
    
            // clone the original options so we can reset on breakpoint change
            this.options._flickityInitialOptions = JSON.parse(JSON.stringify(this.options));
    
            // apply the new options
            var newOptions = this.options.watchCSS[identifier];
    
            for (var key in newOptions) {
              if (newOptions.hasOwnProperty(key)) {
                this.options[key] = newOptions[key];
              }
            }
    
            // update the identifer so we can skip if there's no change in breakpoint
            this._currentOptionsIdentifier = identifier;
          }
        }
      }
    }
    

    const controls = function(container, arg) {
        
        // Arrows
                        
        const prevArrow = container.nextElementSibling.getElementsByClassName('js-previous')[0];
        const nextArrow = container.nextElementSibling.getElementsByClassName('js-next')[0];
        
        prevArrow.addEventListener( 'click', function() {
            arg.previous();
        });
        
        nextArrow.addEventListener( 'click', function() {
            arg.next();
        });
    };

    const selectorMatches = function(el, selector) {
    	var p = Element.prototype;
    	var f = p.matches || p.webkitMatchesSelector || p.mozMatchesSelector || p.msMatchesSelector || function(s) {
    		return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
    	};
    	return f.call(el, selector);
    };
    
    
    window.projectsCarousel = function() {
        
        const projects = document.getElementById('projects');

        const projectsflkty = new Flickity( projects, {
            wrapAround: true,
            cellAlign: 'left',
            freeScroll: true,
            freeScrollFriction: 0.03,
        });
    
        controls(projects, projectsflkty);
                    
       /*
 disablePointerEvents(mechanics, mechanicsflkty);
        
*/
    };


    window.teamCarousel = function() {
        
        const team = document.getElementById('team');

        const teamflkty = new Flickity( team, {
            wrapAround: true,
            cellAlign: 'left',
            freeScroll: true,
            freeScrollFriction: 0.03,
        });
    
        controls(team, teamflkty);
                    
       /*
 disablePointerEvents(mechanics, mechanicsflkty);
        
*/
    };



}, false)