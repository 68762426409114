const loadGoogleMapsApi = require('load-google-maps-api');

(function() {
    
    const obj = document.getElementsByClassName('js-map');
    
    let mapenable = false, int;
    
    const showHideMap = function() {
        const btn = document.getElementsByClassName('js-showmap')[0],
              leadShow = btn.getAttribute('data-show'),
              leadHide = btn.getAttribute('data-hide');
        
        const action = function(e) {
            
            if (obj[0].classList.contains('is-visible')) {
                obj[0].classList.remove('is-visible');
                btn.innerHTML = leadShow;
            } else {
                obj[0].classList.add('is-visible');
                btn.innerHTML = leadHide;
            }
            
            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        };
        
        btn.addEventListener('click', action);
    }

    const initMap = function(el) {
        loadGoogleMapsApi({
            key: 'AIzaSyDzv4gozpcF9CjrI6OWHpLavj2hTLfH4IY'
            }).then(function (googleMaps) {
            
            const lat = Number(el.getAttribute('data-lat')),
                  lng = Number(el.getAttribute('data-lng')),
                  myLatLng = new google.maps.LatLng(lat, lng);
            
            const map = new googleMaps.Map(el, {
                center: myLatLng,
                disableDefaultUI: true,
                zoom: 17,
            })            
			
			const marker = new google.maps.Marker({
				position: myLatLng,
				map: map,
				draggable: false,
				zIndex: 20,
				animation: google.maps.Animation.DROP,				
			});
        
        }).catch(function (error) {
            console.error(error)
        })
    };

    const init = function() {
    
        for (let i = 0; i < obj.length; i ++) {
            initMap(obj[i]);
        }
        
        showHideMap();
    }
    
    
    obj.length > 0 ? init() : false;
    
})();


