import css from './sass/style.scss';

//require('./js/vidbg.js');
require('./js/fonts.js');
require('./js/helpers.js');
require('./js/init.js');
require('./js/career.js');
require('./js/countup.js');
require('./js/filters.js');
require('./js/homevideo.js');
require('./js/inview.js');
require('./js/ismobile.js');
require('./js/map.js');
require('./js/nav.js');
require('./js/polyfills.js');
require('./js/splitting.js');
require('./js/carousels.js');
require('./js/glightbox.js');
require('./js/competences.js');
require('./js/cookies.js');
