document.addEventListener('DOMContentLoaded',function() {
            
    const el = document.getElementById('competences');
    
    const init = function() {
        
        const block = el.getElementsByClassName('js-block');
        const more = el.getElementsByClassName('js-more');
        const nav = el.getElementsByClassName('js-nav')[0],
              content = el.getElementsByClassName('js-content')[0],
              content_item = el.getElementsByClassName('js-item'),
              back = el.getElementsByClassName('js-back'),
              moremobile = el.getElementsByClassName('js-moremobile');

        const backToNav = function() {
            nav.classList.remove('is-hidden');
            content.classList.remove('is-visible');
            
            for (let i = 0; i < content_item.length; i++) {
                content_item[i].classList.remove('is-visible');
                content_item[i].classList.remove('fade-in');
            }
        };

        const clickHandler = function(e) {
            
            let idx = cutme.Helpers.thisIndex(e.currentTarget);

            nav.classList.add('is-hidden');
            content.classList.add('is-visible');
            
            content_item[idx].classList.add('is-visible');
            content_item[idx].getElementsByClassName('js-header')[0].classList.add('startsplit');
            
            setTimeout(function() {
                content_item[idx].classList.add('fade-in');
            }, 10);
            
        };
        
        const mouseLeaveHandler = function(e) {
            
            for (let i = 0; i < more.length; i++) {
                if (more[i].classList.contains('is-active')) {
                    more[i].classList.remove('is-active');
                }
            }            
        };
        
        const mouseOverHandler = function(e) {
            
            let idx = cutme.Helpers.thisIndex(e.currentTarget);
            more[idx].classList.add('is-active');                            
        };
        
        const showArticleMobile = function(e) {
            
            let target = e.currentTarget.parentNode.nextElementSibling,
                more = e.currentTarget.getAttribute('data-more'),
                less = e.currentTarget.getAttribute('data-less'),
                header = e.currentTarget.parentNode.getElementsByClassName('js-header')[0],
                headers = el.getElementsByClassName('js-header');
                
            for (let i = 0; i < headers.length; i ++) {
                headers[i].classList.remove('is-active');
            }

            if (target.classList.contains('is-visible')) {
                
                e.currentTarget.innerHTML = more;
                
                target.classList.remove('fade-in');
                header.classList.remove('is-active');
            
                setTimeout(function() {
                    target.classList.remove('is-visible');
                }, 10);

            } else {

                e.currentTarget.innerHTML = less;
                
                target.classList.add('is-visible');
                header.classList.add('is-active');
                
                setTimeout(function() {
                    target.classList.add('fade-in');
                }, 10);
            }            
        }
        
        for (let i = 0; i < block.length; i ++) {
            
            block[i].addEventListener('mouseenter', mouseOverHandler);
            block[i].addEventListener('mouseleave', mouseLeaveHandler);
            block[i].addEventListener('click', clickHandler);
        }
        
        for (let i = 0; i < back.length; i ++) {
            back[i].addEventListener('click', backToNav);
        }
        
        for (let i = 0; i < moremobile.length; i ++) {
            moremobile[i].addEventListener('click', showArticleMobile);
        }
        
    };
    
    
    el ? init() : false;

}, false);
