const { detect } = require('detect-browser');
const browser = detect();
import Blazy from 'blazy';

document.addEventListener('DOMContentLoaded',function() {

    if (browser) {
        document.documentElement.classList.add(browser.name);
    }

    const cover = document.getElementById('cover');
    
    const init = function() {
        document.body.removeAttribute('style');
        document.body.classList.add('is-loaded');
        
        setTimeout(function() {
            cover.remove();
        }, 250);

        
        // Anims on inview
        window.animsInit()   
        
        // Carousels 
        document.getElementById('projects') ? window.projectsCarousel() : false;
        document.getElementById('team') ? window.teamCarousel() : false;
 
        // Blazy
        
        window.bLazy = new Blazy({
            success: function(el){
            }
        });


        // Home video
        
        document.getElementById('video') ? window.homeVideo() : false;      
        
        
        // Grid
        //document.getElementById('grid') ? window.grid() : false;
        
    };
    
    
    window.addEventListener('load', init);

}, false);